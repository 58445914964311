import Image from 'next/image';

export interface InsuranceCardProps {
  name: string;
  desc: string;
  link: string;
  cardImage: any;
  themeColor: string;
}

export const InsuranceCard = ({ name, desc, link, themeColor, cardImage }: InsuranceCardProps) => {
  return (
    <a href={link}>
      <div className={`flex rounded-lg border gap-4 border-${themeColor}-400 bg-${themeColor}-100 cursor-pointer  px-8`}>
        <div className={`rounded-lg py-1`}>
          <div className="flex flex-col gap-4 py-4">
            <h1 className={`text-title-4 font-bold text-${themeColor}-800`}>{name}</h1>
            <p className="text-body-1">{desc}</p>
            <h1 className={`font-bold text-${themeColor}-700`}>Learn more</h1>
          </div>
        </div>
        <div className="flex items-center justify-end py-1">
          <Image src={cardImage} alt={name} width={210} height={139} />
        </div>
      </div>
    </a>
  );
};
