/* eslint-disable max-lines */
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import { subheaderItems } from '../expanded-header';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
import { useState } from 'react';
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon';
import ArrowUpSLineIcon from 'remixicon-react/ArrowUpSLineIcon';
import { Button, slugify, useWindowWidth } from '@prishapolicy/shared/ui';
import { useRouter } from 'next/router';
import { productsList } from '../expanded-header/products-sub-header/product-list';
import Image from 'next/image';
import { InsideNavItems } from '../expanded-header/products-sub-header/inside-nav-items';
import { CardContent } from '../expanded-header/product-service-nav-card';
import { RemixiconReactIconComponentType } from 'remixicon-react';
import { MobileLoginDrawer } from './mobile-login-flyin';
// import { theme } from 'react-contexify';
export type MobileNavLinks = {
  name: string;
  navName?: string;
  link?: string;
  mobileCardImage?: any;
  list?: {
    name: string;
    description: string;
    theme?: string;
    icon?: any;
    productIcon?: RemixiconReactIconComponentType;
    mobileCardImage?: any;
    subLinks?: {
      name: string;
      productIcon?: any;
    }[];
  }[];
}[];
const MobileNav = (props: { close: () => void }) => {
  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedProductCategory, setSelectedProductCategory] = useState<
    | {
        linkIdx: number;
        nestedLinkIdx: number;
      }
    | undefined
  >(undefined);

  const mobileNavLinks: MobileNavLinks = [
    {
      name: 'Insurance',
      navName: 'products',
      list: productsList.map((productCategory) => {
        return {
          name: productCategory.name,
          description: productCategory.description,
          subLinks: productCategory.products,
          theme: productCategory.theme,
          icon: productCategory.icon,
          mobileCardImage: productCategory.mobileCardImage,
        };
      }),
    },
    ...Object.entries(subheaderItems).map(([key, value]) => {
      return {
        name: key,
        list: value.map((listItem) => {
          return {
            name: listItem.name,
            description: listItem.description,
            productIcon: listItem.productIcon,
          };
        }),
      };
    }),
    // {
    //   name: 'Contact Us',
    // },
    // {
    //   name: 'Become a POSP',
    //   link: 'https://leap.prishapolicy.com/',
    // },
  ];

  const activeLink =
    selectedProductCategory !== undefined ? mobileNavLinks[selectedProductCategory.linkIdx] : undefined;
  const activeNestedLink =
    selectedProductCategory !== undefined && activeLink
      ? activeLink.list?.[selectedProductCategory.nestedLinkIdx]
      : undefined;

  const { width } = useWindowWidth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <>
      <div className="container  z-[500] flex h-[calc(100vh-55px)] flex-col justify-between overflow-auto px-5 pb-7 xl:hidden ">
        <div className="mb-20 flex-1">
          {activeNestedLink === undefined && (
            <Accordion allowZeroExpanded={true} className="grid gap-y-px">
              {mobileNavLinks.map((navLink, idx) => {
                return (
                  <AccordionItem key={idx}>
                    <AccordionItemState>
                      {({ expanded }) => {
                        return (
                          <>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <div
                                  className={`pt-5 ${
                                    expanded ? 'pb-0' : 'pb-5'
                                  } border-grey-200 flex justify-between  ${!expanded && 'border-b'} bg-white`}
                                  onClick={() => {
                                    if (navLink.link) {
                                      window.location.href = navLink.link;
                                      props.close();
                                      return;
                                    }
                                    if (navLink.list === undefined) {
                                      router.push('/' + slugify(navLink.name.toLowerCase()));
                                      props.close();
                                    }
                                  }}
                                >
                                  <span className="text-title-7 font-bold capitalize">{navLink.name}</span>
                                  {navLink.list && (expanded ? <ArrowUpSLineIcon /> : <ArrowDownSLineIcon />)}
                                </div>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div className="flex flex-col gap-5 bg-white py-6">
                                {navLink.list?.map((item, nestedIdx) => {
                                  const descriptionText = new DOMParser().parseFromString(item.description, 'text/html')
                                    .documentElement.textContent;
                                  const disabled = descriptionText?.includes('Coming Soon');

                                  // if (navLink.name === 'resources' && nestedIdx % 2 === 1) {
                                  //   return null;
                                  // }
                                  return (
                                    <div
                                      className={`flex cursor-pointer ${disabled ? 'opacity-50' : ''}`}
                                      key={nestedIdx}
                                      onClick={() => {
                                        if (disabled) return;
                                        if (item.subLinks) {
                                          setSelectedProductCategory({
                                            linkIdx: idx,
                                            nestedLinkIdx: nestedIdx,
                                          });
                                        } else {
                                          // router
                                          router.push('/' + slugify(navLink.name) + '/' + slugify(item.name));
                                          props.close();
                                        }
                                      }}
                                    >
                                      <div
                                        className={`flex w-full marker:flex-1 ${
                                          navLink.name === 'Insurance' && 'gap-5'
                                        }`}
                                      >
                                        {navLink.name !== 'Insurance' ? (
                                          <CardContent
                                            productIcon={item.productIcon}
                                            heading={item.name}
                                            active={false}
                                            description={item.description}
                                          />
                                        ) : (
                                          <div className="w-full ">
                                            <div className="flex w-full items-center justify-between marker:flex-1">
                                              <div className="flex items-center gap-2 py-2">
                                                <div className="py-[7px]"></div>
                                                <Image src={item.icon} width={28} height={13} alt={item.name} />
                                                <h1 className="text-body-1 font-medium">{item.name}</h1>
                                              </div>
                                              <div>
                                                {item.subLinks && (
                                                  <div>
                                                    <ArrowRightSLineIcon className="text-grey-300" />
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </AccordionItemPanel>
                          </>
                        );
                      }}
                    </AccordionItemState>
                  </AccordionItem>
                );
              })}
            </Accordion>
          )}
          {activeLink && activeNestedLink && (
            <div className="flex flex-col gap-6 pt-9">
              <span
                className="text-grey-500 text-title-7 flex cursor-pointer font-medium"
                onClick={() => {
                  setSelectedProductCategory(undefined);
                }}
              >
                <ArrowLeftSLineIcon />
                <div className="ml-2">Insurance</div>
              </span>
              <div>
                <MobileInsuranceCard
                  title={activeNestedLink.name}
                  theme={activeNestedLink.theme}
                  onClose={() => {
                    props.close();
                    window.location.href = '/' + activeLink.navName + '/' + slugify(activeNestedLink.name);
                  }}
                  image={activeNestedLink.mobileCardImage}
                />
              </div>
              <div className="mb-24 flex flex-col gap-3">
                {activeNestedLink.subLinks && (
                  <>
                    {activeNestedLink.subLinks.map((subLink, idx) => {
                      return (
                        <div key={idx}>
                          <InsideNavItems
                            onclose={() => props.close()}
                            name={subLink.name}
                            productIcon={subLink.productIcon?.src}
                            heading={activeNestedLink.name as string}
                            themeColor={activeNestedLink.theme as string}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        <div className={`${width >= 1280 ? 'flex' : 'hidden'}`}>
          <Button href="/about/about-our-posps" text="Become a POS" />
        </div>
        <div className="container fixed  bottom-5 left-0 right-0 z-50 flex w-full justify-center ">
          {activeNestedLink && activeNestedLink.name ? (
            <div className="left-0 right-0  flex w-full flex-col gap-2 ">
              <div className="bg-white">
                <Button
                  text="Track Your Purchase"
                  variant="secondary"
                  theme="outlined"
                  size="large"
                  className="border-grey-200 w-full border bg-white font-medium text-black"
                  leftIcon="configure"
                  onClick={() => {
                    window.location.href = '/track-policy-status';
                  }}
                />
              </div>
              <Button
                text={`Buy ${activeNestedLink.name}`}
                variant="primary"
                size="large"
                className="w-full font-medium "
                rightIcon="arrowRight"
                onClick={() => router.push('https://buy.prishapolicy.com/')}
              />
            </div>
          ) : (
            <Button
              text="Login"
              variant="primary"
              size="large"
              className="w-full"
              onClick={() => setIsDrawerOpen(true)}
            />
          )}
        </div>
      </div>
      <div className="">
        <MobileLoginDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
        {isDrawerOpen && <div className="fixed inset-0  bg-black opacity-25" />}
      </div>
    </>
  );
};

export default MobileNav;

export interface MobileInsuranceCardProps {
  title: string;
  theme?: string;
  onClose: () => void;
  image: any;
}

export const MobileInsuranceCard = ({ title, theme, onClose, image }: MobileInsuranceCardProps) => {
  return (
    <div
      className={`flex cursor-pointer justify-between rounded-lg border border-${theme}-400 bg-${theme}-100 px-5`}
      onClick={onClose}
    >
      <div className="flex items-center ">
        <h1 className={`text-title-6 text-${theme}-600 font-bold`}>{title}</h1>
      </div>
      <Image src={image} alt={title} />
    </div>
  );
};
