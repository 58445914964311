/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { BrandLogoColored } from '@prishapolicy/shared/assets';
import { Button, useVerticalScroll, useWindowWidth } from '@prishapolicy/shared/ui';
import { createContext, Dispatch, SetStateAction, useState } from 'react';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import MenuFillIcon from 'remixicon-react/MenuFillIcon';
import ExpandedHeader from './expanded-header';
import MobileNav from './Mobile-Nav';
// import SearchBar from './search-bar';

import Link from 'next/link';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import { Login } from './expanded-header/nav-login';
const mainLinks = [
  { text: 'Insurance' },
  { text: 'Services' },
  {
    text: 'Resources',
  },
  {
    text: 'About',
  },
  {
    text: 'Support',
    link: '/contact-us',
  },
];

export const CurrentActiveTopLinkContext = createContext<{
  currentActiveTopLink: string;
  setCurrentActiveTopLink: Dispatch<SetStateAction<string>>;
} | null>(null);

export const Header = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isLoginShow, setIsLoginShow] = useState(false);
  const [currentActiveTopLink, setCurrentActiveTopLink] = useState('');
  const vertScroll = useVerticalScroll();
  const { isXl } = useWindowWidth();
  return (
    <CurrentActiveTopLinkContext.Provider
      value={{
        currentActiveTopLink,
        setCurrentActiveTopLink,
      }}
    >
      <div
        style={{ top: 0 }}
        onMouseLeave={() => setCurrentActiveTopLink('')}
        className={`lg-block bg-white/12 fixed  left-1/2 z-40 w-full -translate-x-1/2 transform  transition-all  hover:bg-white/100 ${
          vertScroll > 0 || isMobileNavOpen ? 'bg-white/100' : ''
        }`}
      >
        <div className={`container flex items-center justify-between p-4 `}>
          <div className="flex  items-center  lg:justify-start">
            <Link className="flex cursor-pointer" href="/">
              <BrandLogoColored
                className="w-3/4 lg:w-full"
                onClick={() => {
                  !isXl && setIsMobileNavOpen(false);
                }}
              />
            </Link>
          </div>

          <div className={`text-title-7 hidden gap-x-6 xl:flex`}>
            {mainLinks.map((link, idx) => {
              const onClick = () => {
                if (link.link && window) {
                  window.location.href = link.link;
                }
              };

              const currentActive = currentActiveTopLink === link.text.toLowerCase();
              return (
                <div
                  key={idx}
                  onClick={onClick}
                  onMouseEnter={() => {
                    setCurrentActiveTopLink(link.text.toLowerCase());
                    setIsLoginShow(false);
                  }}
                  className={` flex cursor-pointer items-center gap-1`}
                >
                  <span
                    className={`border-b-secondary border-b-2 font-medium transition-all hover:border-opacity-100  hover:font-bold ${
                      currentActive ? 'border-opacity-100 ' : 'border-opacity-12'
                    }`}
                  >
                    {link.text}
                  </span>
                  <ArrowDownSLineIcon className="text-grey-700 font-normal no-underline" />
                </div>
              );
            })}
          </div>

          <div className="hidden gap-x-4 font-bold xl:flex">
            <div className="relative " onMouseEnter={() => setCurrentActiveTopLink('')}>
              <Button text="Login" size="large" className="cursor-pointer" onClick={() => setIsLoginShow(true)} />
              {isLoginShow && isXl && (
                <div className="absolute top-full right-[500px] mt-[17px] w-full">
                  <Login setClose={setIsLoginShow} isLoginShow={isLoginShow} />
                </div>
              )}
            </div>
          </div>
          <button
            aria-label="Toggle Navigation"
            className={`justify-self-end xl:hidden`}
            onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
          >
            {isMobileNavOpen ? <CloseLineIcon /> : <MenuFillIcon />}
          </button>
        </div>
        <ExpandedHeader />
        {isMobileNavOpen && (
          <MobileNav
            close={() => {
              setIsMobileNavOpen(false);
            }}
          />
        )}
      </div>
    </CurrentActiveTopLinkContext.Provider>
  );
};
