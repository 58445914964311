import Image from 'next/image';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';

interface InsideNavItemsProps {
  name: string;
  productIcon: any;
  heading: string;
  themeColor: string;
  onclose?: () => void;
}

export const InsideNavItems = ({ name, productIcon, heading, themeColor, onclose }: InsideNavItemsProps) => {
  const mainUrl = heading && heading.toLowerCase().replace(/\s+/g, '-');
  const subUrl = name.toLowerCase().replace(/\s+/g, '-');
  const productUrl = `/products/${mainUrl}/${subUrl}`;
  return (
    <a href={productUrl}>
      <div className={`hover:bg-surface-${themeColor} cursor-pointer rounded-md bg-white`} onClick={onclose && onclose}>
        <div className="flex  w-full items-center justify-between p-3">
          <div className="flex items-center gap-2">
            <div className={`rounded-md bg-${themeColor}-200 p-2`}>
              <Image src={productIcon} alt={heading} width={20} height={20} />
            </div>
            <h1 className="text-body-1 font-medium ">{name}</h1> 
          </div>
          <div className="flex items-center">
            <ArrowRightSLineIcon />
          </div>
        </div>
      </div>
    </a>
  );
};
