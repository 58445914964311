import { useContext } from 'react';
import { CurrentActiveTopLinkContext } from '..';
import ProductsSubHeader from './products-sub-header';
import serviceIcons from './service-icons';
import resourceIcons from './resource-icons';
import NonProductSubHeader, { NonProductSubHeaderProps } from './non-product-sub-header';
import aboutIcons from './about-icons';
import AlertLineIcon from 'remixicon-react/AlertLineIcon';
import FilePaperLineIcon from 'remixicon-react/FilePaperLineIcon';
import Folder5LineIcon from 'remixicon-react/Folder5LineIcon';
import StackLineIcon from 'remixicon-react/StackLineIcon';
import ServerLineIcon from 'remixicon-react/ServerLineIcon';
import ArticleLineIcon from 'remixicon-react/ArticleLineIcon';
import FileListLineIcon from 'remixicon-react/FileListLineIcon';
import BookMarkLineIcon from 'remixicon-react/BookMarkLineIcon';
import CalculatorLineIcon from 'remixicon-react/CalculatorLineIcon';
import ListCheck2Icon from 'remixicon-react/ListCheck2Icon';
import GitRepositoryLineIcon from 'remixicon-react/GitRepositoryLineIcon';
import InformationLineIcon from 'remixicon-react/InformationLineIcon';
import StarSmileLineIcon from 'remixicon-react/StarSmileLineIcon';
import ShieldUserLineIcon from 'remixicon-react/ShieldUserLineIcon';
import FileList3LineIcon from 'remixicon-react/FileList3LineIcon';
import BriefcaseLineIcon from 'remixicon-react/BriefcaseLineIcon';
import ShieldKeyholeLineIcon from 'remixicon-react/ShieldKeyholeLineIcon';
import ServiceLineIcon from 'remixicon-react/ServiceLineIcon';
import Settings5LineIcon from 'remixicon-react/Settings5LineIcon';
import NavigationLineIcon from 'remixicon-react/NavigationLineIcon';
import { TrackYourPurchase } from './products-sub-header/track-purchase';
import { ResourcesBlogs } from './products-sub-header/nav-services/blogs-card';
import { LatestNews } from './products-sub-header/nav-services/article-card';
import { TestimonialSlider } from './products-sub-header/nav-services/testimonial/testimonial-data';

export const subheaderItems: {
  [key: string]: NonProductSubHeaderProps['items'];
} = {
  services: [
    {
      name: 'Risk Inspection',
      description: 'Our team helps companies across the country understand their risks',
      icon: serviceIcons.risk,
      activeColor: 'red-300',
      productIcon: AlertLineIcon,
    },
    {
      name: 'Claim Settlement Services',
      description: 'Our post sale services are free and we stand by you to get the claim',
      icon: serviceIcons.claim,
      activeColor: 'yellow-300',
      productIcon: FilePaperLineIcon,
    },
    {
      name: 'Insurance Audit',
      description: 'Get the best insurance advisors in town to audit your current plans',
      icon: serviceIcons.audit,
      activeColor: 'green-300',
      productIcon: Folder5LineIcon,
    },
    {
      name: 'Designing Insurance Program',
      description: 'Make the best insurance plan by customizing it according to your needs',
      icon: serviceIcons.design,
      activeColor: 'teal-300',
      productIcon: StackLineIcon,
    },
    {
      name: 'Surgical Assistance',
      description: 'Get advanced surgical treatments at an affordable rate',
      icon: serviceIcons.service,
      activeColor: 'teal-300',
      productIcon: ServerLineIcon,
    },
  ],
  resources: [
    {
      name: 'Blog',
      description: 'Read our latest articles and blogs',
      icon: resourceIcons.blogs,
      activeColor: 'yellow-300',
      productIcon: ArticleLineIcon,
    },
    {
      name: 'Checklists',
      description: 'Coming Soon!',
      icon: resourceIcons.checklists,
      productIcon: ListCheck2Icon,
    },

    {
      name: 'Insurance Resources',
      description: 'Find insurance wordings, brochures, and forms all at one place',
      icon: resourceIcons.resources,
      activeColor: 'red-400',
      productIcon: FileListLineIcon,
    },
    {
      name: 'Academy',
      description: 'Coming Soon!',
      icon: resourceIcons.academy,
      productIcon: GitRepositoryLineIcon,
    },
    {
      name: 'Glossary',
      description: 'Learn about all things insurance',
      icon: resourceIcons.glossary,
      activeColor: 'green-300',
      productIcon: BookMarkLineIcon,
    },
    {
      name: 'Calculators',
      description: 'Coming Soon!',
      icon: resourceIcons.calculators,
      productIcon: CalculatorLineIcon,
    },
  ],
  about: [
    {
      name: 'Who we are',
      description: ' companies across the country understand their risks',
      icon: aboutIcons.who,
      activeColor: 'blue-300',
      productIcon: InformationLineIcon,
    },
    {
      name: 'About our POSPs',
      description: 'Our post sale services are free and we stand by you to get the claim',
      icon: aboutIcons.posps,
      activeColor: 'yellow-300',
      productIcon: ShieldUserLineIcon,
    },
    {
      name: 'Careers',
      description: 'The team that helps us reach out to you more closely.',
      icon: aboutIcons.careers,
      activeColor: 'green-300',
      productIcon: BriefcaseLineIcon,
    },
    {
      name: 'Press & Awards',
      description: 'Make the best insurance plan by customising it according to your needs',
      icon: aboutIcons.press,
      activeColor: 'teal-300',
      productIcon: StarSmileLineIcon,
    },

    {
      name: 'Terms & Conditions',
      description: 'Make the best insurance plan by customising it according to your needs',
      icon: aboutIcons.terms,
      activeColor: 'red-300',
      productIcon: FileList3LineIcon,
    },
    {
      name: 'Privacy Policy',
      description: 'The team that helps us reach out to you more closely.',
      icon: aboutIcons.privacy,
      activeColor: 'purple-200',
      productIcon: ShieldKeyholeLineIcon,
    },
  ],
  support: [
    {
      name: 'Raise a Claim',
      description: 'In need of a claim against your insurance, fill out the details and Raise a Claim',
      icon: resourceIcons.calculators,
      productIcon: ServiceLineIcon,
    },
    {
      name: 'Contact Nearest Advisor',
      description: 'Coming Soon!',
      icon: resourceIcons.calculators,
      productIcon: ShieldUserLineIcon,
    },
    {
      name: 'Anything Else?',
      description: 'Got anything else in your mind? Contact us to get your worries rectified',
      icon: resourceIcons.calculators,
      productIcon: InformationLineIcon,
    },
    {
      name: 'Need Service Support',
      description: 'Coming Soon!',
      icon: resourceIcons.calculators,
      productIcon: Settings5LineIcon,
    },
    {
      name: 'Track Your Purchase',
      description: 'Coming Soon!',
      icon: resourceIcons.calculators,
      productIcon: NavigationLineIcon,
    },
  ],
};
const ExpandedHeader = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { currentActiveTopLink } = useContext(CurrentActiveTopLinkContext)!;
  return !['insurance', 'services', 'resources', 'about', 'support'].includes(currentActiveTopLink) ? null : (
    <div className=" relative z-40 hidden bg-white xl:block">
      <div className="flex">
        <ul className={`container hidden overflow-hidden pb-2 transition-all xl:flex`}>
          {currentActiveTopLink === 'insurance' ? (
            <ProductsSubHeader />
          ) : (
            <div className="flex flex-col gap-5">
              <div className="grid grid-cols-2 gap-7">
                <div className="col-span-1 flex flex-col gap-5">
                  <div className="">
                    {currentActiveTopLink !== 'insurance' && (
                      <h3 className="text-title-6 font-bold">
                        {
                          {
                            insurance: 'Product Categories',
                            services: 'Services',
                            resources: 'Resources',
                            about: 'About Us',
                            support: 'Support',
                          }[currentActiveTopLink]
                        }
                      </h3>
                    )}
                  </div>
                  <NonProductSubHeader items={subheaderItems[currentActiveTopLink]} prefix={currentActiveTopLink} />
                </div>
                <div className="col-span-1">
                  {currentActiveTopLink === 'services' && <TestimonialSlider />}
                  {currentActiveTopLink === 'resources' && <ResourcesBlogs />}
                  {currentActiveTopLink === 'about' && <LatestNews />}
                  {currentActiveTopLink === 'support' && <ResourcesBlogs />}
                </div>
              </div>
              <TrackYourPurchase primaryButtonText="Want to Buy Insurance" />
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ExpandedHeader;
