import { Button } from '@prishapolicy/shared/ui';
import { useRouter } from 'next/router';

export interface TrackYourPurchaseProps {
  primaryButtonText?: string;
}

export const TrackYourPurchase = ({ primaryButtonText }: TrackYourPurchaseProps) => {
  const router = useRouter();
  return (
    <div className="bg-surface-blue  rounded-lg">
      <div className="flex flex-1 gap-2 py-2 px-2">
        <div className="bg-white">
          <Button
            text="Track Your Purchase"
            variant="primary"
            theme="white"
            size="large"
            leftIcon="configure"
            className="border-grey-300 border font-medium "
            onClick={() => router.push('/track-policy-status')}
          />
        </div>
        <Button
          text={primaryButtonText}
          size="large"
          rightIcon="arrowRight"
          className="font-medium"
          onClick={() => {router.push('https://buy.prishapolicy.com/')}}
        />
      </div>
    </div>
  );
};
