import React, { useState, useEffect } from 'react';
import { healthInsurance, motorInsurance, termInsurance } from '@prishapolicy/w3/assets';
import { PospCard } from '@prishapolicy/w3/ui';

interface SliderProps {
  slides: JSX.Element[];
  className?: string;
}

export const Slider: React.FC<SliderProps> = ({ slides, className }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? slides.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const autoSlideInterval = setInterval(handleNext, 2000); // Slide every 2 seconds
    return () => clearInterval(autoSlideInterval);
  }, []);

  return (
    <div className="slider">
      <div className="slide">{slides[currentIndex]}</div>
      <div className={`relative ${className} flex items-center justify-center gap-3`}>
        {slides.map((_, index) => (
          <button key={index} onClick={index === currentIndex ? handlePrev : handleNext}>
            {index === currentIndex ? <Ellipses /> : <Oval />}
          </button>
        ))}
      </div>
    </div>
  );
};

export const Ellipses = () => {
  return <div className="h-2 w-7 rounded-[5px] bg-grey-700"></div>;
};

export const Oval = () => {
  return <div className="bg-grey-200 h-[10px] w-[10px] rounded-full"></div>;
};

export const AboutPospSlider = () => {
  const slideContent = [
    <div key="motor">
      <PospCard
        bgColor={'bg-grad-purple-tmdm'}
        insuranceName={'Motor Insurance'}
        image={motorInsurance}
        preMoney={25000}
        commissionMoney={3500}
      />
    </div>,
    <div key="health">
      <PospCard
        bgColor={'bg-grad-green-tmdm'}
        insuranceName={'Health Insurance'}
        image={healthInsurance}
        preMoney={10000}
        commissionMoney={3000}
      />
    </div>,
    <div key="term">
      <PospCard
        bgColor={'bg-grad-red-tmdm'}
        insuranceName={'Term Insurance'}
        image={termInsurance}
        preMoney={25000}
        commissionMoney={2500}
      />
    </div>,
  ];
  return <Slider slides={slideContent} className='-top-10' />;
};
